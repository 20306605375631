<template>
  <div class="lightling-box">
    <el-form :model="form" ref="form" label-width="200px" class="form-box">
      <div class="title-bar-left">
        <div class="left-line"></div>
        <div class="title-name">基本信息</div>
      </div>
      <el-form-item
        label="认证设计师名称："
        prop="name"
        :rules="[{ required: true, message: '请输入认证设计师名称' }]"
      >
        <el-input
          v-model="form.name"
          style="width: 300px"
          placeholder="请输入"
          :disabled="isView"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="头像："
        prop="hearUrl"
        :rules="[{ required: true, message: '请上传头像' }]"
      >
        <el-upload
          :disabled="isView"
          class="upload-demo1"
          :action="projectName + '/common/backstage/file/fileupload'"
          :headers="headers"
          :show-file-list="false"
          :on-success="avatarSuccess"
          :before-upload="beforeUpload"
        >
          <el-image
            v-if="form.hearUrl"
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(form.hearUrl)
            "
            class="adv-img1"
            :preview-src-list="[
              projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(form.hearUrl),
            ]"
          >
          </el-image>
          <!-- <img
                        v-if="form.hearUrl"
                        :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(form.hearUrl)"
                        class="adv-img1"
                    /> -->
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <div
        v-for="(item1, index2) in form.certificateNames"
        :key="index2"
        class="list-box"
      >
        <div
          class="img-add"
          @click="addClick"
          v-if="form.certificateNames.length > 0 && isView != true"
        >
          <img
            src="../productManagement/img/add.png"
            style="width: 30px; height: 30px"
          />
        </div>
        <div
          class="img-add"
          @click="deleteClick(index2)"
          v-if="index2 >= 1 && isView != true"
        >
          <img
            src="../productManagement/img/deletebtn.png"
            style="width: 30px; height: 30px"
          />
        </div>
        <el-form-item
          label="设计师证书："
          :prop="'certificateNames.' + index2 + '.name'"
          :rules="[{ required: true, message: '请输入设计师证书' }]"
        >
          <el-input
            maxlength="16"
            v-model="item1.name"
            style="width: 300px"
            placeholder="请输入（最多输入16字）"
            :disabled="isView"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item
        label="设计理念："
        prop="intro"
        :rules="[{ required: true, message: '请输入设计理念' }]"
      >
        <el-input
          type="textarea"
          maxlength="48"
          v-model="form.intro"
          style="width: 500px"
          placeholder="请输入（最多输入48字）"
          :disabled="isView"
        ></el-input>
      </el-form-item>

      <div class="title-bar-left">
        <div class="left-line"></div>
        <div class="title-name">作品信息</div>
      </div>

      <div
        v-for="(item, index1) in form.productList"
        :key="index1 + '123'"
        class="list-box"
      >
        <div
          class="img-add"
          @click="addWorksClick"
          v-if="index1 == 0 && isView != true"
        >
          <img
            src="../productManagement/img/add.png"
            style="width: 30px; height: 30px"
          />
        </div>
        <div
          class="img-add"
          @click="deleteWorksClick(index1)"
          v-if="index1 >= 1 && isView != true"
        >
          <img
            src="../productManagement/img/deletebtn.png"
            style="width: 30px; height: 30px"
          />
        </div>
        <el-form-item
          label="作品名称："
          :prop="'productList.' + index1 + '.descriptions'"
          :rules="[{ required: true, message: '请输入作品名称' }]"
        >
          <el-input
            v-model="item.descriptions"
            style="width: 300px"
            placeholder="请输入"
            :disabled="isView"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="作品封面："
          :prop="'productList.' + index1 + '.productionUrl'"
          :rules="[{ required: true, message: '请上传作品封面' }]"
        >
          <el-upload
            :disabled="isView"
            class="upload-demo"
            :action="projectName + '/common/backstage/file/fileupload'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="handlebeforeUpload(index1)"
          >
            <!-- <img
              @mouseenter="deletePicCliCK(index1, 1)"
              v-if="item.productionUrl"
              :src="
                projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(item.productionUrl)
              "
              class="adv-img"
            /> -->
           <div @mouseenter="deletePicCliCK(index1, 1)">
            <el-image
              v-if="item.productionUrl"
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(item.productionUrl)
            "
            class="adv-img"
            :preview-src-list="[
              projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(item.productionUrl),
            ]"
          >
          </el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
           </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="设计方案："
          :prop="'productList.' + index1 + '.descriptionImage'"
          :rules="[{ required: true, message: '请上传设计方案' }]"
        >
          <el-upload
            :disabled="isView"
            class="upload-demo2"
            :action="projectName + '/common/backstage/file/fileupload'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess2"
            :before-upload="handlebeforeUpload2(index1)"
          >
          <div  @mouseenter="deletePicCliCK(index1, 2)">
          <el-image
              v-if="item.descriptionImage"
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(item.descriptionImage)
            "
            class="adv-img2"
            :preview-src-list="[
              projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(item.descriptionImage),
            ]"
          >
          </el-image>
            <!-- <img
              v-if="item.descriptionImage"
              :src="
                projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(item.descriptionImage)
              "
              class="adv-img2"
            /> -->
            <!-- <div class="delete-pic" v-if="item.descriptionImage" @click.stop="deletePicCliCK(index1,2)">×</div> -->
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
        <div class="table-line" v-if="form.productList.length >= 2"></div>
      </div>

      <div class="btn-box" v-if="isView != true">
        <el-button @click="$router.go(-1)">取 消</el-button>
        <el-button type="primary" @click="makeSure" v-if="!lightingSchemeId"
          >确 定</el-button
        >
        <el-button type="primary" @click="editSure" v-if="lightingSchemeId"
          >确 定</el-button
        >
      </div>
      <div class="btn-box" v-if="isView == true">
        <el-button @click="$router.go(-1)">返 回</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { lightingAdd, lightingEdit, lightingDetails } from "../../api/index";
import { projectName } from "../../util/config";
export default {
  data() {
    return {
      projectName: projectName,
      headers: { sessionId: sessionStorage.getItem("access_token") },
      form: {
        certificateNames: [{ name: "" }],
        hearUrl: "",
        productList: [
          { descriptions: "", productionUrl: "", descriptionImage: "" },
        ],
      },
      ind: 0,
      ind2: 0,
      lightingSchemeId: "",
      isView: false,
      isEdit: 0,
    };
  },
  mounted() {
    if (this.$route.query.lightingSchemeId) {
      this.lightingSchemeId = this.$route.query.lightingSchemeId;
      this.isView = this.$route.query.isView;
      this.isEdit = this.$route.query.isEdit;
      this.getDetails();
    }
  },
  methods: {
    addClick() {
      let data = this.form.certificateNames || [];
      if (data.length > 2) {
        this.$message.warning("最多添加三个证书");
      } else {
        this.form.certificateNames.push({ name: "" });
      }
    },
    avatarSuccess(res, file) {
      this.form.hearUrl = res.data.nginxFileReadPath;
    },
    beforeUpload() {},
    handleAvatarSuccess(res, file) {
      let data = res.data.nginxFileReadPath;
      this.$set(this.form.productList[this.ind], "productionUrl", data);
    },
    handlebeforeUpload(index) {
      console.log(index, "hhhhhhhhhhhh");
      // 新增才执行这一步
      if (this.isEdit == 0) {
        this.ind = index;
      }
    },
    deletePicCliCK(index, num) {
      console.log(index, "dddd");
      if (num == 1) {
        this.ind = index;
      } else {
        this.ind2 = index;
      }

      // console.log(this)
      // let arr = this.form.productList;
      // arr.forEach((item,index)=>{
      //     if(this.ind ==index && num==1) {
      //         item.productionUrl = ""
      //     } else {
      //         item.descriptionImage = ""
      //     }
      // })
    },
    handleAvatarSuccess2(res, file) {
      let data = res.data.nginxFileReadPath;
      this.$set(this.form.productList[this.ind2], "descriptionImage", data);
      console.log(this.form, "fff");
    },
    handlebeforeUpload2(index) {
      this.ind2 = index;
    },
    deleteClick(index) {
      let dataArr = this.form.certificateNames;
      if (dataArr.length >= 2) {
        this.form.certificateNames.splice(index, 1);
      } else {
      }
    },
    addWorksClick() {
      // this.form.productList.push({descriptions:'',productionUrl:""})
      let data = this.form.productList;
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].productionUrl == "" ||
          data[i].descriptions == "" ||
          data[i].descriptionImage == ""
        ) {
          this.$message.warning("请完善当前信息！");
          return;
        }
      }
      this.form.productList.push({
        descriptions: "",
        productionUrl: "",
        descriptionImage: "",
      });
    },
    deleteWorksClick(index) {
      let dataArr = this.form.productList;
      if (dataArr.length >= 2) {
        this.form.productList.splice(index, 1);
      } else {
      }
    },
    makeSure() {
      let data = {
        ...this.form,
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          lightingAdd(data).then((res) => {
            if (res.code == 1) {
              this.$message.success("新增成功！");
              this.$router.go(-1);
            }
          });
        }
      });
    },
    editSure() {
      let data = {
        ...this.form,
        lightingSchemeId: this.lightingSchemeId,
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          lightingEdit(data).then((res) => {
            if (res.code == 1) {
              this.$message.success("编辑成功！");
              this.$router.go(-1);
            }
          });
        }
      });
    },
    getDetails() {
      lightingDetails({ lightingSchemeId: this.lightingSchemeId }).then(
        (res) => {
          if (res.code == 1) {
            let data = res.data || {};
            this.form = data || {};
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.lightling-box {
  width: 100%;
  .form-box {
    width: 900px;
    margin: 0 auto;
  }
  .title-bar-left {
    display: flex;
    justify-content: flex-start;
    margin: 20px;
  }
  .left-line {
    width: 6px;
    height: 20px;
    background: #144393;
    border-radius: 0px 3px 3px 0px;
    margin-right: 10px;
  }
  .title-name {
    font-size: 16px;
    font-weight: bold;
    color: #666666;
  }
  .list-box {
    position: relative;
    .img-add {
      position: absolute;
      left: 510px;
      top: 4px;
      z-index: 9;
      cursor: pointer;
    }
  }
}
.table-line {
  width: 100%;
  height: 1px;
  background: #dcdfe6;
  margin: 0 0 20px 20px;
}
.btn-box {
  text-align: center;
  margin-top: 30px;
}
.upload-demo /deep/.el-upload {
  width: 200px;
  height: 150px;
  border: 1px dashed #e5e5e5;
  position: relative;
  .el-icon-plus {
    margin-top: 60px;
    font-size: 40px;
    color: #e5e5e5;
  }
}
.delete-pic {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
  font-size: 16px;
  color: #ffffff;
  line-height: 20px;
  z-index: 99999;
}
.upload-demo1 /deep/.el-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #e5e5e5;
  border-radius: 50%;
  .el-icon-plus {
    margin-top: 35px;
    font-size: 30px;
    color: #e5e5e5;
  }
}
.upload-demo2 {
  width: 200px;
  height: 450px;
  border: 1px dashed #e5e5e5;
  position: relative;
  .el-icon-plus {
    margin-top: 200px;
    margin-left: 75px;
    font-size: 40px;
    color: #e5e5e5;
  }
}
.adv-img1 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.adv-img {
  width: 200px;
  height: 150px;
}
.adv-img2 {
  width: 200px;
  height: 450px;
}
</style>